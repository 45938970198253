import React from "react"
import Header from "../components/header-details"
import MallDetails from "../components/mall-details"
import MallDetailsInactive from "../components/mall-details-inactive"
import MallDetailsClosed from "../components/mall-details-closed"
import AdsDetails from "../components/ads-details"
import Slideshow from "../components/slideshow"
import SocialShare from '../components/socialshare';
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Map from '../components/map';
// do not remove this import, it loads the styles for the page
import Layout from "../components/layout";
import EmailSignUp from "../components/email-signup";
import distance from '@turf/distance'
import '../components/map.css';
import mapboxgl from "mapbox-gl";
import candycane from "../images/candy-cane.png"
import { getFullUrl } from '../../url';

class DetailsPageOLD extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {

    mapboxgl.accessToken = 'pk.eyJ1IjoibWVyZ2VjaGljYWdvIiwiYSI6ImNrMHljMThsdDBlYW4zY29ib2JhYXhlZWYifQ.uem2bbxL5oqjRsx9FoqIgQ';

    const mall = this.props.data.strapiMall;

      var map = new mapboxgl.Map({
          container: 'map-detail-container',
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [mall.Longitude, mall.Latitude],
          zoom: 15
      });

      map.on('load', function (e) {
          console.debug("loads")

          map.loadImage(
              candycane,
              function (error, image) {
                  if (error) throw error;
                  map.addImage('custom-marker', image);
                  // Add a GeoJSON source with 2 points
                  map.addSource('points', {
                      'type': 'geojson',
                      'data': {
                          'type': 'FeatureCollection',
                          'features': [
                              {
                                  'type': 'Feature',
                                  'geometry': { 'type': 'Point', 'coordinates': [mall.Longitude, mall.Latitude] },
                                  'properties': { 'title': mall.Title }
                              }
                          ]
                      }
                  });

                  // Add a symbol layer
                  map.addLayer({
                      'id': 'points',
                      'type': 'symbol',
                      'source': 'points',
                      'layout': {
                          'icon-image': 'custom-marker',
                          // get the title name from the source's "title" property
                          'text-field': ['get', 'title'],
                          'text-offset': [0, 1.25],
                          'text-anchor': 'top'
                      }
                  });
              }
          );



      });
  }


  render() {
    
      const renderDetails = () => {
          if (this.props.data.strapiMall.Active == true) {
              if (this.props.data.strapiMall.Closed == true) {
                  return <MallDetailsClosed mall={this.props.data} />
              }
              else {
                  return <MallDetails mall={this.props.data} />
              }
              
          } else {
              return <MallDetailsInactive mall={this.props.data} />
          }
      }

        
    return (
    <>
    <Helmet>
        <meta http-equiv="Refresh" content={"0; URL=https://www.whereissanta.com/" + getFullUrl(this.props.data.strapiMall.State, this.props.data.strapiMall.Title, this.props.data.strapiMall.Mall_Number)}></meta>
    </Helmet>
            <SEO title={`${this.props.data.strapiMall.Title}`} keywords={`${this.props.data.strapiMall.Meta_Keywords}`} description={`${this.props.data.strapiMall.Meta_Description}`} />
    <div className="full-width" id="detailsPage">
      <Header mallid={`${this.props.data.strapiMall.id}`} mall={`${this.props.data.strapiMall.Title}`} startdate={`${this.props.data.strapiMall.Starting_From}`} />
                <div id="railL" className="cols">
                    {renderDetails()}
                <div className="col2-3 malldetailspageR col-m-1">
                <div id="map-detail-container"></div>
          <AdsDetails mall={this.props.data} />
          <EmailSignUp size={'narrow'} />
          <SocialShare />
        </div>
      </div>
      <Footer title={''} />
    </div>
    </>
    )
    

  }
}


export default DetailsPageOLD

export const query = graphql`
 
  query DetailsPageOLD($id: String) {
    strapiMall(id: { eq: $id }) {
      id
      Title
      Active
      Website
      Phone
      Reservation
      Latitude
      Longitude
      Developer
      Address
      City
      State
      Zip
      Hours {
          Start_Date
          End_Date
          Day
          Hours
      }
      Photos {
          Title
          Image {
            childImageSharp {
              fluid(quality: 72) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
      Ads {
          Title
          Image {
            childImageSharp {
              fluid(quality: 72) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          Logo {
            childImageSharp {
              fluid(quality: 72) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          Link
          Offer_Headline
          Offer_Content
      }
      Events {
        Label
        Title
        Description
        Registration_Link
        Dates
      }
      Where_Is_Santa
      Where_Is_Santa_Map
      {
        childImageSharp {
          fluid(quality: 72) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Starting_From
      Meta_Keywords
      Meta_Description
      Closed
    }
  }
`
